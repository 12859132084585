// Add this to your RegisterComponent class

import { Component, ViewEncapsulation, inject } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { AuthenticationService } from '../../data/services/authentication.service';
import { determinePasswordStrength, passwordStrengthValidator } from '../../../shared/data/functions/password.functions';
import { ServerError } from '@rr/dashboard';
import { Subscription } from 'rxjs';
import { mobileRegionValidator, mobileTypeValidator } from '@assentinel/forms';
import { PhoneNumberType } from 'google-libphonenumber';
import { AnalyticsService } from '@rr/shared';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RegisterComponent {
    analyticsService = inject(AnalyticsService);
    private gaClientId: string | null = null;
    isCountryDropdownOpen = false;
    countryCodeCtrl = new FormControl();
    availableCountries = [
      { code: 'AU', name: 'Australia' },
      { code: 'NZ', name: 'New Zealand' }
    ];



    msisdnCtrl = new FormControl('', { 
      nonNullable: true, 
      validators: [ 
        Validators.required, 
        mobileTypeValidator(PhoneNumberType.MOBILE) 
      ]
    });
    
    emailCtrl = new FormControl('', { nonNullable: true, validators:[ Validators.required, Validators.email ] });
    passwordCtrl = new FormControl('', { nonNullable: true, validators:[ Validators.required, passwordStrengthValidator ] });
    registerSubscription: Subscription;

    // Register form - add countryCode
    registerForm = new FormGroup({
        givenName: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
        middleName: new FormControl<string | null>(null),
        familyName: new FormControl('', { nonNullable: true, validators: Validators.required }),
        countryCode: this.countryCodeCtrl,
        msisdn: this.msisdnCtrl,
        email: this.emailCtrl, 
        password: this.passwordCtrl,
        promoCode: new FormControl<string | null>(null),
    });

    constructor(private authService: AuthenticationService) {
        this.setGAClientID();

    }
    
    /**
     * Register user with updated form structure
     */
    register() {
        if (this.registerForm.valid) {
            const registrationData = {
                ...this.registerForm.getRawValue(),
                // Include country code for backend if needed
                // countryCode: this.countryCodeCtrl.value,
                gaClientId: this.gaClientId
            };
            
            this.registerSubscription = this.authService
                .signup(registrationData)
                .subscribe({
                    next: (response) => {
                        this.analyticsService.gtmEvent('registration', {
                            promoCode: this.registerForm.controls.promoCode.value
                        });
                        
                        this.authService.rememberDetails(this.msisdnCtrl.value);
                        this.authService.gotoWebapp();
                    },
                    error: (error: ServerError) => {
                        if (error.code == 'ER-260') {
                            this.registerForm.setErrors({ duplicate: true });
                        } else {
                            // Handle other errors
                        }
                    }
                });
        } else {
            console.warn('Registration form is invalid!', this.registerForm);
        }
    }

    setGAClientID() {
        this.gaClientId = this.getGAClientIDFromCookie();
    }

    getGAClientIDFromCookie(): string | null {
        const gaCookie = document.cookie
          .split('; ')
          .find(row => row.startsWith('_ga='));
        if (gaCookie) {
          const gaCookieValue = gaCookie.split('=')[1];
          const parts = gaCookieValue.split('.');
          if (parts.length === 4) {
            return `${parts[2]}.${parts[3]}`;
          }
        }
        return null;
    }

    get passwordStrength(): string {
        return determinePasswordStrength(this.passwordCtrl.value);
    }
    
    error(control: AbstractControl) {
        return control.errors && Object.entries(control.errors)[0][0];
    }
}