import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Currency } from '@assentinel/forms';
import { transactionDetails } from 'projects/dashboard-lib/src/lib/data/types/transaction-details';

@Component({
  templateUrl: './remit-error.component.html',
  styleUrls: ['./remit-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RemitErrorComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: transactionDetails) {}
}