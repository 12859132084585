<div class="rr-load">

    <h3 class="dsb-title">
        <span class="txt">Load your Rocket Remit account:</span>
    </h3>

    <div class="rr-card">
        <div class="tab-content">
            <span class="btn-close" (click)="closeAction()">
                <span class="txt">close</span>
                <span class="ico"></span>
            </span>
            <h3 class="lc-title">
                <span class="ico card"></span>
                <span class="txt">Instant load with Credit/Debit&nbsp;Card:</span>
            </h3>
            <div class="description">
                <p>Load your Rocket Remit account using your credit or debit card. Funds are loaded instantly.</p>
            </div>
            <form [formGroup]="loadFormGrp" novalidate="true" (ngSubmit)="load()">

                <div class="load-amount">
                    <div class="price">
                        <mat-form-field hideRequiredMarker="true">
                            <!-- <mat-label>send amount</mat-label>  -->
                            <input matInput [axCurrency]="senderCurrency" [formControl]="loadAmountCtrl" required>
                            <span matPrefix class="dollar">$</span>
                            <span matSuffix>{{ senderCurrency }}</span>
                            <div *ngIf="loadAmountCtrl.invalid" class="alert-block">
                                <span class="ico"></span>
                                <div [ngSwitch]="firstError(loadAmountCtrl)" class="help-tooltip">
                                    <span *ngSwitchCase="'required'" class="txt">amount is required</span>
                                    <span *ngSwitchCase="'min'" class="txt">amount is below the minimum limit of {{
                                        loadAmountCtrl.errors.min.min | axCurrency:senderCurrency }} {{ senderCurrency }}</span>
                                    <span *ngSwitchCase="'max'" class="txt">amount is above the maximum limit of {{
                                        loadAmountCtrl.errors.max.max | axCurrency:senderCurrency }} {{ senderCurrency }}</span>
                                    <span *ngSwitchDefault class="txt">invalid amount</span>
                                    <span class="rect"></span>
                                </div>
                            </div>
                        </mat-form-field>

                        <div class="pr-des">
                            <span class="txt">min credit/debit amount ${{ limit?.min | axCurrency:senderCurrency }}</span>
                        </div>
                    </div>
                    <div class="fee" *ngIf="fee">
                        <span class="txt">+ ${{ fee.fixed | axCurrency:senderCurrency }} fee</span>
                    </div>
                </div>

                <button type="submit" class="btn btn-primary" [promiseBtn]="loadSubscription">
                    <span class="btn-label">LOAD ACCOUNT NOW</span>
                    <div class="btn-spinner-wrapper ng-scope"><span class="btn-spinner"></span></div>
                </button>
            </form>
            <div class="description">
                <span class="info">Note - Depending on your bank and the type of card, you may be charged a cash advance
                    fee and/or interest by your bank.</span>
            </div>
        </div>
    </div>
</div>