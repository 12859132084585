<div class="dialog-container">
  <h2 class="dialog-title">Password Change Successful</h2>
  <div class="dialog-content">
    <p>
      Your password has been successfully changed. You can now log in with your
      new credentials.
    </p>
  </div>
  <div class="dialog-actions">
    <button
      mat-button
      color="primary"
      class="dialog-button"
      (click)="closeDialog()"
    >
      Login
    </button>
  </div>
</div>
