<mat-form-field hideRequiredMarker="true">
    <mat-label>mobile number</mat-label>
    <input matInput axMsisdn [countryCode]="countryCodeCtrl.value" [formControl]="msisdnCtrl" required>
    <mat-select matPrefix [formControl]="countryCodeCtrl">
        <mat-select-trigger>
            <span class="icon" class="flag-icons AU" [ngClass]="countryCodeCtrl.value"></span>
        </mat-select-trigger>
        <mat-option value="AU"><span class="icon" class="flag-icons AU"></span> Australia</mat-option>
        <mat-option value="NZ"><span class="icon" class="flag-icons NZ"></span> New Zealand</mat-option>
    </mat-select>
    @if (msisdnCtrl.invalid && (msisdnCtrl.dirty || msisdnCtrl.touched)) {
        <div class="alert-block">
            <span class="ico"></span>
            <div class="help-tooltip">
                <span class="rect"></span>
                @switch (error(msisdnCtrl)) {
                    @case ('required') {
                        <span class="txt">Mobile number is required</span>
                    }
                    @case ('country-required') {
                        <span class="txt">Please select country</span>
                    }
                    @case ('invalid-region') {
                        <span class="txt">Invalid {{ countryCode }} Number</span>
                    }
                    @case ('invalid-type') {
                        <span class="txt">Must be a mobile number</span>
                    }
                    @default {
                        <span class="txt">Mobile number is invalid</span>
                    }
                }
            </div>
        </div>
    }
</mat-form-field>