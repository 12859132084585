<div class="remit-complete-dialog">
  <h2 class="dialog-title">
    Transaction Submitted Successfully!
  </h2>
  
  <div class="dialog-content">
    <div class="reference-section">
      <label>Reference Number:</label>
      <div class="reference-value">{{data.referenceNumber}}</div>
    </div>

    <div class="details-section">
      <div class="details-row">
        <div class="amount-section">
          <div class="label">You Send</div>
          <div class="amount">{{data.sendAmount | axCurrency:data.sendCurrency }} {{ data.sendCurrency }}</div>
        </div>
        <div class="amount-section">
          <div class="label">They Receive</div>
          <div class="amount">{{ data.beneficiaryAmount | axCurrency:data.receiveCurrency }} {{ data.receiveCurrency }}</div>
        </div>
      </div>

      <div class="rate-info">
        <div class="info-item">
          <label>Exchange Rate:</label>
          <span>1 {{ data.sendCurrency }} = {{ fxRate }} {{ data.receiveCurrency }}</span>
        </div>
        <div class="info-item">
          <label>Transfer Fee:</label>
          <span>{{ data.fee | axCurrency:data.sendCurrency }} {{ data.sendCurrency }}</span>
        </div>
      </div>
    </div>

    <!-- Beneficiary Details -->
    <div class="beneficiary-section">
      <h3>Beneficiary Details</h3>
      <div class="beneficiary-info">
        <div class="info-item">
          <label>Name:</label>
          <span>{{data.beneficiary.name}}</span>
        </div>
        <div class="info-item">
          <label>Mobile Number:</label>
          <span>{{data.beneficiary.msisdn}}</span>
        </div>
        <div class="info-item">
          <label>Country:</label>
          <span>{{data.beneficiary.country}}</span>
        </div>
        <div class="info-item" *ngIf="data.moneyOperator">
          <label>Money Operator:</label>
          <span>{{data.moneyOperator.name}}</span>
        </div>
      </div>
    </div>

    <!-- Transaction Time -->
    <div class="timestamp">
      Transaction Time: {{data.timestamp | date:'medium'}}
    </div>

    <div class="info-message">
      We have sent an SMS message to your mobile phone with additional transaction details.
    </div>
  </div>

  <div class="dialog-actions">
    <button type="button" class="btn btn-primary" mat-dialog-close>CLOSE</button>
  </div>
</div>