import { Component, HostListener } from '@angular/core';
import { Validators, FormControl, AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { mobileRegionValidator, mobileTypeValidator } from '@assentinel/forms';
import { PhoneNumberType } from 'google-libphonenumber';
import { AuthenticationService } from '../../data/services/authentication.service';

/**
 * `PasswordForgotComponent` allows users to initiate a password reset request by providing their mobile number.
 * The component utilizes Angular forms for data binding, validation, and submission.
 * It also uses Angular's Router for navigation.
 *
 * @property {FormControl} countryCodeCtrl - Form control for the user's country selection.
 * @property {FormControl} msisdnCtrl - Form control for the user's mobile number.
 * @property {FormGroup} forgotPasswordForm - Form group containing all form controls.
 */
@Component({
    selector: 'app-password-forgot',
    templateUrl: './password-forgot.component.html',
    styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent {

    // Mobile number control with validation
    msisdnCtrl = new FormControl<string>('', { 
        nonNullable: true, 
        validators: [
            Validators.required,
            mobileTypeValidator(PhoneNumberType.MOBILE)
        ] 
    });
    
    // Form group containing all controls
    forgotPasswordForm = new FormGroup({
        msisdn: this.msisdnCtrl
    });

    /**
     * Constructor initializes the Router and defines the form group and controls for the password forgot form.
     *
     * @param {Router} router - The router for navigating between routes.
     * @param {AuthenticationService} authService - service for handling auth related data services
     */
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) { }
    

    /**
     * Handles the password forgot form submission, resets form errors,
     * and navigates to the reset password route after submitting request to the server.
     */
    passwordForgot(): void {
        if (this.msisdnCtrl.valid) {
            const msisdn = this.msisdnCtrl.value;

            this.authService
                .forgotPassword(msisdn)
                .subscribe({
                    error: (_) => {
                        this.router.navigate([ '/reset-password' ], {
                            queryParams: {
                                msisdn: msisdn
                            },
                            skipLocationChange: true
                        });
                    },
                    // Always route to the reset-password screen, even if there is an error
                    // This prevents the ability to "phish" for registered msisdns
                    complete: () => {
                        this.router.navigate([ '/reset-password' ], {
                            queryParams: {
                                msisdn: msisdn
                            },
                            skipLocationChange: true
                        });
                    }
                });
        } else {
            this.msisdnCtrl.markAllAsTouched();
        }
    }

    /**
     * Returns the key of the first error from the provided form control, useful for error messaging.
     * @param {AbstractControl} control - The form control to inspect for errors.
     * @returns {string} - The key of the first error, or undefined if no errors are present.
     */
    error(control: AbstractControl) {
        return control.errors && Object.entries(control.errors)[0][0];
    }
}