<div class="remit-error-dialog">
  <h2 class="dialog-title">
    Transaction Error!
  </h2>
  
  <div class="dialog-content">
    <div class="reference-section">
      <label>Reference Number:</label>
      <div class="reference-value">{{data.referenceNumber}}</div>
    </div>

    <!-- Transaction Time -->
    <div class="timestamp">
      Transaction Time: {{data.timestamp | date:'medium'}}
    </div>

    <div class="info-message">
      We have sent an SMS message to your mobile phone with additional transaction details.
    </div>
  </div>

  <div class="dialog-actions">
    <button type="button" class="btn btn-primary" mat-dialog-close>CLOSE</button>
  </div>
</div>