<!-- HTML Template for Login with Flag-Only Selector -->
<div class="wrapper">
  <div class="login">
    <a class="btn-hide-form" [routerLink]="['/']">
      <span class="txt">close</span>
      <span class="ico"></span>
    </a>
    
    <h2>Welcome back!</h2>
    
    <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate="true">
      <!-- Mobile Number Field with Flag Selector -->
      <div class="row">
        <div class="form-group">

          <rr-customer-msisdn [formControl]="usernameCtrl" countryCode="AU"></rr-customer-msisdn>
          
          <div class="additional-links">
            <a href="#/register" class="link">Don't have an account? Signing up is easy!</a>
          </div>
        </div>
        
        <!-- Password Field (unchanged) -->
        <div class="form-group">
          <mat-form-field hideRequiredMarker="true">
            <mat-label>password</mat-label>
            <input matInput type="password" [formControl]="passwordCtrl" required>
            <div class="alert-block">
              <span class="ico"></span>
              <div class="help-tooltip">
                <span class="rect"></span>
                <span class="txt">Password is required</span>
              </div>
            </div>
          </mat-form-field>
          <div class="additional-links">
            <a [routerLink]="['/forgot-password']" class="link">Forgot your password? No worries! Click here.</a>
          </div>
        </div>
      </div>
      
      <!-- Auth Failure Alert (unchanged) -->
      <div *ngIf="authFailure" class="alert alert-warning" role="alert">
        <div>
          <div class="txt">Your mobile number or password is incorrect!</div>
        </div>
      </div>
      
      <!-- Login Button (unchanged) -->
      <div class="buttons-wrap">
        <button type="submit" class="btn btn-primary" [promiseBtn]="loginSubscription">
          <span class="btn-label">LOGIN NOW!</span>
          <div class="btn-spinner-wrapper ng-scope"><span class="btn-spinner"></span></div>
        </button>
      </div>
    </form>
  </div>
</div>