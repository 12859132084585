<div class="registration-form">
  <a class="btn-hide-form" [routerLink]="['/']">
    <span class="txt">close</span>
    <span class="ico"></span>
  </a>
  <div class="form-box">
    <div class="wrapper">
      <h2 class="form-title">
        <span class="txt">Reset Password</span>
      </h2>

      <div class="steps-container">
        <div [@stepAnimation]="currentStep">
          <!-- Step 1: OTP Input -->
          <div class="step-content step-1" *ngIf="currentStep === 1">
            <p class="description">
              A password reset code has been sent to your mobile number.<br />
              Please enter the code to continue.
            </p>
            <form [formGroup]="otpForm" (ngSubmit)="nextStep()">
              <div
                class="otp-container"
                [ngClass]="{ error: otpError }"
              >
                <ng-otp-input
                  [config]="otpConfig"
                  formControlName="otp"
                ></ng-otp-input>
                <div class="error-message" *ngIf="otpError">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary"
                  [disabled]="!otpForm.valid"
                >
                  <span class="btn-label">CONTINUE</span>
                </button>
              </div>
            </form>
          </div>

          <!-- Step 2: New Password -->
          <div class="step-content step-2" *ngIf="currentStep === 2">
            <p class="description">
              Please choose your new password.
            </p>
            <form
              [formGroup]="resetPasswordForm"
              (ngSubmit)="resetPassword()"
              novalidate
              autocomplete="off"
            >
              <div class="row">
                <div class="col-md-offset-3 col-md-6">
                  <mat-form-field appearance="outline" class="passwordfield">
                    <mat-label>New Password</mat-label>
                    <input
                      matInput
                      type="password"
                      formControlName="password"
                    />
                    <mat-error *ngIf="passwordCtrl.errors?.['strengthLow']">
                      Password strength is low.
                    </mat-error>
                  </mat-form-field>
                  <div class="password-strength" *ngIf="passwordCtrl.value">
                    Password Strength: <b>{{ passwordStrength }}</b>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary"
                  [disabled]="!resetPasswordForm.valid"
                >
                  <span class="btn-label">SUBMIT</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
