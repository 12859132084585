<!-- Forgot Password Form with Flag-Only Selector -->
<div class="registration-form">
  <a class="btn-hide-form" [routerLink]="['/']">
    <span class="txt">close</span>
    <span class="ico"></span>
  </a>
  <div class="form-box">
    <div class="wrapper">
      <form (ngSubmit)="passwordForgot()" novalidate autocomplete="off" [formGroup]="forgotPasswordForm">
        <h2 class="form-title">
          <span class="txt">Forgotten Password</span>
        </h2>
        <div class="row">
          <div class="col-md-offset-3 col-md-6">
            <!-- Phone container with flag selector and input -->

            <rr-customer-msisdn formControlName="msisdn" countryCode="AU"></rr-customer-msisdn>
          </div>
        </div>
        
        <div class="row">
          <div class="form-group">
            <button type="submit" class="btn btn-primary"><span class="btn-label">NEXT</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>