import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'country-popup',
  templateUrl: './country-popup.component.html',
  styleUrls: ['./country-popup.component.scss']
})
export class CountryPopupComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

  selectCountry(countryCode: string) {
    // Save the selected country to localStorage
    // localStorage.setItem('CountryOfOrigin', countryCode.toLowerCase());
    
    this.setCookie('CountryOfOrigin', countryCode.toLowerCase(), 30);

    // Simply refresh the page - the WordPress script will handle the redirect
    window.location.reload();
  }

  // Helper function to set cookies
  setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }
}