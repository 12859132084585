import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Currency } from '@assentinel/forms';
import { transactionDetails } from 'projects/dashboard-lib/src/lib/data/types/transaction-details';

@Component({
  selector: 'rr-remit-complete',
  templateUrl: './remit-complete.component.html',
  styleUrls: ['./remit-complete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RemitCompleteComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: transactionDetails) {}

  get fxRate(): number | null {
  
    if (this.data) {

        const sendCurrency = Currency.of(this.data.sendCurrency);
        const receiveCurrency = Currency.of(this.data.receiveCurrency);

        const displayRate = this.data.fxRate * Math.pow(10, sendCurrency.exponent - receiveCurrency.exponent);
        const precision = Math.pow(10, Math.min(3, Math.max(0, 4 - Math.floor(Math.log10(displayRate)))));

        return Math.round(displayRate * precision) / precision;
    }

    return null;
  }
}