import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-password-success-dialog',
  templateUrl: './password-success-dialog.component.html',
  styleUrls: ['./password-success-dialog.component.scss'],
})
export class PasswordSuccessDialogComponent {
  constructor(private dialogRef: MatDialogRef<PasswordSuccessDialogComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
