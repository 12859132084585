<div class="rr-pay">

    <h3 class="dsb-title">
        <span class="txt">Send money overseas:</span>
    </h3>
    <div class="steps-container">
        <div>
            <div class="step-content" *ngIf="currentView === 'form'">
                <div class="tab-content">
                    <div class="description" [class.disabled]="registrationStatus !== 'complete'">
                        <p>Please fill out the fields below to begin your instant money transfer.</p>
                    </div>

                    <form class="ds-form" [formGroup]="remitFormGroup" (ngSubmit)="remit()" novalidate="true">
                        <div [class.disabled]="registrationStatus !== 'complete'">
                            <div formGroupName="beneficiary">
                                <div class="row">
                                    <div class="form-group">
                                        <mat-form-field hideRequiredMarker="true" class="beneficiary-msisdn">
                                            <div matPrefix>
                                                <span class="icon" class="flag-icons" [ngClass]="beneficiaryPrefixIcon"></span>
                                            </div>
                                            <mat-label>receivers mobile number</mat-label>
                                            <input matInput formControlName="msisdn" [rrMsisdnFavorites]="favorites" matAutocompletePosition="below" required>
                                            <mat-autocomplete #favorites="matAutocomplete" [displayWith]="displayMsisdn" (optionSelected)="beneficiarySelected($event)" >
                                                <div class="rr-beneficiary-favorite" *ngFor="let beneficiary of filteredBeneficiaries | async">
                                                    <mat-option class="" [value]="beneficiary">
                                                        <div class="msisdn"><span class="flag-icons" [ngClass]="getRegionCodeForNumber(beneficiary.msisdn)"></span>{{ beneficiary.msisdn | msisdn }}</div>
                                                        <div *ngIf="beneficiary.moneyOperator" class="money-operator">{{ beneficiary.moneyOperator.name }}</div>
                                                        <div class="full-name">{{ beneficiary.firstName }} {{ beneficiary.surname }}</div>
                                                        <div class="address">{{ beneficiary.address }}</div>
                                                        <div *ngIf="beneficiary.cityTownSuburb" class="city-town-suburb">{{ beneficiary.cityTownSuburb }}</div>
                                                    </mat-option>
                                                    <button class="rr-delete" (click)="deleteFav(beneficiary.id)">&times;</button>
                                                </div>
                                            </mat-autocomplete>

                                            <div class="help-block">
                                                <span class="ico"></span>
                                                <div class="help-tooltip">
                                                    <span class="txt">use international format including the country code (e.g. +639196123456)</span>
                                                    <span class="rect"></span>
                                                </div>
                                            </div>
                                            <div *ngIf="error(getControl('beneficiary', 'msisdn'))" class="alert-block">
                                                <span class="ico"></span>
                                                <div [ngSwitch]="error(getControl('beneficiary', 'msisdn'))" class="help-tooltip">
                                                    <span *ngSwitchCase="'required'" class="txt">receivers mobile number is required</span>
                                                    <span *ngSwitchCase="'unsupported'" class="txt">receivers mobile number not supported</span>
                                                    <span *ngSwitchDefault class="txt">use international format including the country code (e.g. +639196123456)</span>
                                                    <span class="rect"></span>
                                                </div>
                                            </div> 
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row" *ngIf="collectMoneyOperator">
                                    <div class="form-group">
                                        <mat-form-field>
                                            <mat-label>money operator</mat-label>
                                            <mat-select [formControl]="moneyOperatorCtrl" disableOptionCentering="true" panelClass="select-panel" #matSelect="matSelect" (openedChange)="moSelectOpened(matSelect, $event)">
                                                <mat-select-trigger #matSelectTrigger>{{ moneyOperatorName }}</mat-select-trigger>
                                                <mat-option *ngFor="let moneyOperator of corridorInfo.moneyOperators" [value]="moneyOperator">{{ moneyOperator.name }}</mat-option>
                                            </mat-select>
                                            <div class="alert-block">
                                                <span class="ico"></span>
                                                <div [ngSwitch]="error(moneyOperatorCtrl)" class="help-tooltip">
                                                    <span *ngSwitchCase="'required'" class="txt">money operator is required</span>
                                                    <span *ngSwitchDefault class="txt">money operator is invalid</span>
                                                    <span class="rect"></span>
                                                </div>
                                            </div> 
                                        </mat-form-field>
                                    </div>
                                </div>
                                <ng-template #moneyOperatorComponent></ng-template>
                                <div class="row">
                                    <div class="form-group half-part">
                                        <mat-form-field hideRequiredMarker="true">
                                            <mat-label>receivers first name</mat-label>
                                            <input matInput type="text" formControlName="firstName" minlength="2" required>
                                            <div *ngIf="error(getControl('beneficiary', 'firstName'))" class="alert-block">
                                                <span class="ico"></span>
                                                <div [ngSwitch]="error(getControl('beneficiary', 'firstName'))" class="help-tooltip">
                                                    <span *ngSwitchCase="'required'" class="txt">receivers first name is required</span>
                                                    <span *ngSwitchCase="'minlength'" class="txt">receivers first name is to short</span>
                                                    <span *ngSwitchCase="'diacritic'" class="txt">name cannot contain accented characters</span>
                                                    <span *ngSwitchDefault class="txt">receivers first name is invalid</span>
                                                    <span class="rect"></span>
                                                </div>
                                            </div> 
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group half-part">
                                        <mat-form-field hideRequiredMarker="true">
                                            <mat-label>receivers last name</mat-label>
                                            <input matInput type="text" formControlName="surname" minlength="2" required>
                                            <div *ngIf="error(getControl('beneficiary', 'surname'))" class="alert-block">
                                                <span class="ico"></span>
                                                <div [ngSwitch]="error(getControl('beneficiary', 'surname'))" class="help-tooltip">
                                                    <span *ngSwitchCase="'required'" class="txt">receivers last name is required</span>
                                                    <span *ngSwitchCase="'minlength'" class="txt">receivers last name is to short</span>
                                                    <span *ngSwitchCase="'diacritic'" class="txt">name cannot contain accented characters</span>
                                                    <span *ngSwitchDefault class="txt">receivers last name is invalid</span>
                                                    <span class="rect"></span>
                                                </div>
                                            </div> 
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="address-group">
                                    <div class="row">
                                        <div class="form-group">
                                            <mat-form-field hideRequiredMarker="true">
                                                <mat-label>receivers residential address</mat-label>
                                                <textarea matInput formControlName="address" cdkTextareaAutosize cdkAutosizeMinRows="2" minlength="10" required></textarea>
                                                <div *ngIf="error(getControl('beneficiary', 'address'))" class="alert-block">
                                                    <span class="ico"></span>
                                                    <div [ngSwitch]="error(getControl('beneficiary', 'address'))" class="help-tooltip">
                                                        <span *ngSwitchCase="'required'" class="txt">receivers address is required</span>
                                                        <span *ngSwitchCase="'insufficent'" class="txt">more address information required</span>
                                                        <span *ngSwitchCase="'pobox'" class="txt">address cannot be a PO box</span>
                                                        <span *ngSwitchCase="'diacritic'" class="txt">address cannot contain accented characters</span>
                                                        <span *ngSwitchDefault class="txt">receivers address is invalid</span>
                                                        <span class="rect"></span>
                                                    </div>
                                                </div> 
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group half-part">
                                            <mat-form-field hideRequiredMarker="true">
                                                <mat-label>receivers city or town</mat-label>
                                                <input matInput type="text" formControlName="cityTownSuburb" minlength="2" required>
                                                <div *ngIf="error(getControl('beneficiary', 'cityTownSuburb'))" class="alert-block">
                                                    <span class="ico"></span>
                                                    <div [ngSwitch]="error(getControl('beneficiary', 'cityTownSuburb'))" class="help-tooltip">
                                                        <span *ngSwitchCase="'required'" class="txt">receivers city / town is required</span>
                                                        <span *ngSwitchCase="'insufficent'" class="txt">more city / town information required</span>
                                                        <span *ngSwitchCase="'diacritic'" class="txt">city / town cannot contain accented characters</span>
                                                        <span *ngSwitchDefault class="txt">receivers city / town is invalid</span>
                                                        <span class="rect"></span>
                                                    </div>
                                                </div> 
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group half-part">
                                            <mat-form-field hideRequiredMarker="true">
                                                <mat-label>receivers country</mat-label>
                                                <input matInput type="text" [value]="beneficiaryCountry" required disabled>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row description">
                                <p>How much would you like to send?</p>
                            </div>
                            <div class="row amount">
                                <div class="form-group half-part">
                                    <mat-form-field hideRequiredMarker="true" [class.rr-form-field-error]="!!error(sendAmountCtrl)">
                                        <mat-label>send amount</mat-label>
                                        <input matInput [axCurrency]="senderCurrency" [formControl]="sendAmountCtrl">
                                        <div matSuffix>{{ senderCurrency }}</div>
                                        <div *ngIf="error(sendAmountCtrl)" class="alert-block">
                                            <span class="ico"></span>
                                            <div [ngSwitch]="error(sendAmountCtrl)" class="help-tooltip">
                                                <span *ngSwitchCase="'required'" class="txt">send amount is required</span>
                                                <span *ngSwitchCase="'min'" class="txt">amount is below the minimum send limit of {{ sendAmountCtrl.errors.min.min | axCurrency:senderCurrency }} {{ senderCurrency }}</span>
                                                <span *ngSwitchCase="'max'" class="txt">amount is above the maximum send limit of {{ sendAmountCtrl.errors.max.max | axCurrency:senderCurrency }} {{ senderCurrency }}</span>
                                                <span *ngSwitchCase="'insufficient'" class="txt">you do not have enough available funds in you account</span>
                                                <span class="rect"></span>
                                            </div>
                                        </div>
                                        <mat-hint *ngIf="corridorInfo">Includes <span>{{ corridorInfo.fee | axCurrency:corridorInfo.sendCurrency }} {{ corridorInfo.sendCurrency }}</span> Fee</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="form-group half-part">
                                    <mat-form-field hideRequiredMarker="true">
                                        <mat-label>receive amount</mat-label>
                                        <div *ngIf="!moneyOperatorTiers; then anyAmount else tieredAmount"></div>
                                        <ng-template #anyAmount>
                                            <input  matInput [axCurrency]="corridorInfo && corridorInfo.receiveCurrency" [formControl]="beneficiaryAmountCtrl" required>
                                        </ng-template>
                                        <ng-template #tieredAmount>
                                            <select matNativeControl [formControl]="beneficiaryAmountCtrl">
                                                <option [value]="" selected></option>
                                                <option *ngFor="let amountTier of moneyOperatorTiers" [value]="amountTier">{{ amountTier | axCurrency:corridorInfo.receiveCurrency }}</option>
                                            </select>
                                        </ng-template>
                                        <!-- <ng-template>
                                            <mat-select matNativeControl formControlName="beneficiaryAmount" disableOptionCentering="true" panelClass="select-panel" #matSelect="matSelect">
                                                <mat-option *ngFor="let amountTier of moneyOperatorTiers" [value]="amountTier">{{ amountTier | axCurrency:corridorInfo.receiveCurrency }}</mat-option>
                                            </mat-select>
                                        </ng-template> -->
                                        <div matSuffix>{{ corridorInfo && corridorInfo.receiveCurrency }}</div>
                                        <div *ngIf="error(beneficiaryAmountCtrl)" class="alert-block">
                                            <span class="ico"></span>
                                            <div [ngSwitch]="error(beneficiaryAmountCtrl)" class="help-tooltip">
                                                <span *ngSwitchCase="'required'" class="txt">receiver amount is required</span>
                                                <span *ngSwitchCase="'min'" class="txt">amount is below the minimum receiver limit of {{ corridorInfo.receiveLimit.min | axCurrency:corridorInfo.receiveCurrency }} {{ corridorInfo.receiveCurrency }}</span>
                                                <span *ngSwitchCase="'max'" class="txt">amount is above the maximum receiver limit of {{ corridorInfo.receiveLimit.max | axCurrency:corridorInfo.receiveCurrency }} {{ corridorInfo.receiveCurrency }}</span>
                                                <span class="rect"></span>
                                            </div>
                                        </div>
                                        <mat-hint *ngIf="corridorInfo">Exchange rate: 1 {{ corridorInfo.sendCurrency }} = {{ fxRate }} {{ corridorInfo.receiveCurrency }}</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="canRemit; else remitDisabled">

                            <div class="alert alert-warning" role="alert" *ngIf="remitFormGroup.errors && remitForm?.submitted" [ngSwitch]="error(remitFormGroup)">
                                <div *ngSwitchCase="'suspended'">
                                    <div class="txt">Your account is suspended.</div>
                                    <div class="txt">Please contact <a href="mailto:hello@rocketremit.com">hello&#64;rocketremit.com</a></div>
                                </div>
                                <div *ngSwitchCase="'invalid-money-operator'">
                                    <div class="txt">The money operator is no longer available.</div>
                                </div>
                                <div *ngSwitchDefault>
                                    <div class="txt">An error occured when attempting to send money.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group">
                                    <button type="submit" class="btn btn-primary" [promiseBtn]="remitSubscription">
                                        <span class="btn-label">SEND NOW!</span>
                                        <div class="btn-spinner-wrapper ng-scope"><span class="btn-spinner"></span></div>
                                    </button>
                                </div>
                            </div>

                        </ng-container>

                        <ng-template #remitDisabled [ngSwitch]="true">

                            <ng-container *ngSwitchCase="suspended">
                                <div class="alert alert-warning" role="alert">
                                    <div class="txt">Your account is suspended.</div>
                                    <div class="txt">Please contact <a href="mailto:hello@rocketremit.com">hello&#64;rocketremit.com</a></div>
                                </div>
                            </ng-container>

                            <ng-container *ngSwitchDefault [ngSwitch]="registrationStatus">
                                <ng-container *ngSwitchCase="'complete'"></ng-container>
                                <div class="alert alert-warning" role="alert" *ngSwitchCase="'verificationPending'">
                                    <div class="txt">Your account verification is currently waiting for review.</div>
                                    <div class="txt">Please contact <a href="mailto:hello@rocketremit.com">hello&#64;rocketremit.com</a></div>
                                </div>
                                <ng-container *ngSwitchDefault>
                                    <div class="alert alert-warning" role="alert">
                                        <div class="txt">To send money overseas you need to complete your registration</div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <button type="button" class="btn btn-primary" (click)="completeRegistration.emit()">FINISH REGISTRATION</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                    </form>
                </div>
            </div>
            <!-- OTP confirmation view -->
            <div class="step-content otp-view" *ngIf="currentView === 'otp'">
                <remit-confirm 
                [referenceNumber]="referenceNumber"
                [remitAdvice]="lastRemitAdvice"
                [corridorInfo]="lastCorridorInfo">
                </remit-confirm>
            </div>
        </div>
</div>