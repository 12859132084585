<div class="tab-content">
  <span class="btn-close" (click)="navigateHome()">
    <span class="txt">close</span>
    <span class="ico"></span>
  </span>
  <p class="description">
    Please enter the verification code sent to your mobile number to complete the transaction.
  </p>
  <form [formGroup]="otpForm" (ngSubmit)="confirmRemit()">
  <div class="row">
    <div class="col-md-12 otp-container" [ngClass]="{'error': otpError}">
      <ng-otp-input [config]="otpConfig" formControlName="otp" (onInputChange)="onOtpChange($event)"></ng-otp-input>
      <div class="error-message" *ngIf="otpError">
        {{ errorMessage }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group">
      <button type="submit" class="btn btn-primary" *ngIf="!showBackButton" (click)="confirmRemit()"
        [disabled]="!otpForm.valid" [promiseBtn]="confirmSubscription">
        <span class="btn-label">CONFIRM TRANSFER</span>
        <div class="btn-spinner-wrapper ng-scope"><span class="btn-spinner"></span></div>
      </button>
      <button type="button" class="btn btn-primary" *ngIf="showBackButton" (click)="restartTransaction()">
        <span class="btn-label">BACK</span>
      </button>
    </div>
  </div>
  </form>
</div>
