export class ServerError extends Error {

    public code: string;
    public timestamp: Date;
    public metadata?: any;

    constructor(error: any) {

        super(error.errorMessage)

        this.code = error.errorCode
        this.timestamp = new Date(error.timestamp)
        this.metadata = error.metadata
    }
}