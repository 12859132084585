import { Component, Inject, OnInit, Optional, ViewEncapsulation, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';

import { AuthService, AxLoginComponent, AxUser, MAT_DIALOG_LOGIN_CONFIG, MatDialogLoginConfig } from '@assentinel/auth';
import { mobileTypeValidator } from '@assentinel/forms';
import { PhoneNumberType } from 'google-libphonenumber';
import { AuthenticationService } from '../../data/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent extends AxLoginComponent implements OnInit {

  
  // Update username control with validators for mobile number
  override usernameCtrl = new FormControl('', { 
    validators: [
      Validators.required,
      mobileTypeValidator(PhoneNumberType.MOBILE)
    ]
  });
  
  override passwordCtrl = new FormControl('', [Validators.required]);
  
  // Update form group to include country code
  loginForm = new FormGroup({
    msisdn: this.usernameCtrl,
    password: this.passwordCtrl
  });
  
  constructor(
    private rrAuthService: AuthenticationService,
    axAuthService: AuthService,
    @Optional() @Inject(MAT_DIALOG_LOGIN_CONFIG) config: MatDialogLoginConfig
  ) {
    super(axAuthService, config);
  }
  

  
  ngOnInit(): void {
    // get msisdn from local storage and patch into username
    const msisdn = this.rrAuthService.getPreviousMsisdn();
    
    if (msisdn) {
      this.usernameCtrl.patchValue(msisdn);
    }
    
    // deal with loggedIn events
    this.loggedIn.subscribe({
      next: (user: AxUser) => {
        this.rrAuthService.rememberDetails(user.username);
        this.rrAuthService.gotoWebapp();
      }
    });
  }
  
  override login() {

    console.log('login', this.loginForm)

    if (this.loginForm.valid) {
      // Include country code in the login process if needed
      // You may need to adjust this depending on how your authentication service works
      super.login();
    }
  }
  
  error(control: AbstractControl) {
    return control.errors && Object.entries(control.errors)[0][0];
  }
}