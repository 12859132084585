import { CommonModule } from '@angular/common';
import { Component, forwardRef, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormControlStatus, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AxMsisdnModule, mobileRegionValidator, mobileTypeValidator } from '@assentinel/forms';
import { PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'rr-customer-msisdn',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AxMsisdnModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ],
  templateUrl: './customer-msisdn.component.html',
  styleUrl: './customer-msisdn.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomerMsisdnComponent),
    }, {
      provide: NG_VALIDATORS, 
      useExisting: forwardRef(() => CustomerMsisdnComponent), 
      multi: true
    }
  ],
})
export class CustomerMsisdnComponent implements ControlValueAccessor, Validator, OnInit {

  countryCodeCtrl = new FormControl(null, { 
      validators: [
        Validators.required 
      ]
  })
  msisdnCtrl = new FormControl(null, { 
      validators: [
        Validators.required,
        mobileTypeValidator(PhoneNumberType.MOBILE)
      ]
    })

  private onChange = (value: string) => {}
  private onTouched = () => {}
  private onValidatorChange = () => {}

  private phoneUtil = PhoneNumberUtil.getInstance()

  ngOnInit(): void {
    // Get country from cookie
    this.setCountryFromCookie();
    
    this.countryCodeCtrl
      .valueChanges
      .subscribe((countryCode: string) => {
        console.log('changed country code', countryCode)
        
        // Set CountryOfOrigin cookie when user changes country
        if (countryCode) {
          const country = countryCode.toLowerCase();
          document.cookie = `CountryOfOrigin=${country}; path=/; max-age=31536000`; // 1 year expiration
        }
        
        this.msisdnCtrl.setValidators([
            Validators.required,
            mobileRegionValidator(countryCode),
            mobileTypeValidator(PhoneNumberType.MOBILE)
        ])
        this.msisdnCtrl.updateValueAndValidity({ onlySelf: true })
        this.msisdnCtrl.markAsDirty({ onlySelf: true })

        console.log('re-validate msisdn', this.msisdnCtrl)
      })

    this.msisdnCtrl
      .valueChanges
      .subscribe((value: string) => {
        console.log('msisdn', value)
        this.onChange(value)
      })

    this.msisdnCtrl
      .statusChanges
      .subscribe((status: FormControlStatus) => {
        console.log('status changed', status)

        if (status === 'VALID' && !this.countryCodeCtrl.value) {

          console.log('no country selected, lookup from msisdn')
          const phoneNum = this.phoneUtil.parse(this.msisdnCtrl.value)

          const countryCode = this.phoneUtil.getRegionCodeForNumber(phoneNum)
          if (countryCode) {
            this.countryCodeCtrl.setValue(countryCode.toUpperCase())
            return
          }
        }

        if (status === 'VALID') {
          console.log('status changed to valid', this.msisdnCtrl)
        }

        this.onValidatorChange()
      })
  }

  setCountryFromCookie(): void {
    // Get all cookies and parse them
    const cookies = document.cookie.split(';');
    let countryCode = null;
    
    // Look for the CountryOfOrigin cookie
    for (let cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'CountryOfOrigin') {
        countryCode = value;
        break;
      }
    }
    
    // Set to 'nz' if cookie is 'nz', otherwise default to 'au'
    const selectedValue = countryCode === 'nz' ? 'nz' : 'au';
    this.countryCodeCtrl.setValue(selectedValue.toUpperCase());
  }

  get countryCode(): string {
    return this.countryCodeCtrl.value
  }

  writeValue(msisdn: string): void {
    
    console.log('writeValue', msisdn)

    this.msisdnCtrl.setValue(msisdn)

    if (msisdn) {
      const phoneNum = this.phoneUtil.parse(msisdn)

      const countryCode = this.phoneUtil.getRegionCodeForNumber(phoneNum)
      if (countryCode) {
        this.countryCodeCtrl.setValue(countryCode.toUpperCase())
        return
      }
    }
  }

  registerOnChange(fn: any): void {
    console.log('registerOnChange', fn)
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.msisdnCtrl.disable
    } else {
      this.msisdnCtrl.enable
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {

    if (this.msisdnCtrl.invalid && this.countryCodeCtrl.invalid) {
      return { 'country-required': 'msisdn country selection required' }
    }
    return this.msisdnCtrl.errors
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn
  }

  error(control: AbstractControl) {
    return control.errors && Object.entries(control.errors)[0][0];
  }
}