import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../data/services/authentication.service';
import { trigger, transition, style, animate, group, query } from '@angular/animations';
import { determinePasswordStrength, passwordStrengthValidator } from '../../../shared/data/functions/password.functions';
import { MatDialog } from '@angular/material/dialog';
import { PasswordSuccessDialogComponent } from '../password-success-dialog/password-success-dialog.component';

@Component({
  selector: 'app-password-reset',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('stepAnimation', [
      transition('1 => 2', [
        group([
          query(':leave', [
            style({ position: 'absolute', transform: 'translateX(0)' }),
            animate('700ms ease-in-out', style({ transform: 'translateX(-100%)' })),
          ]),
          query(':enter', [
            style({ position: 'absolute', transform: 'translateX(100%)' }),
            animate('700ms ease-in-out', style({ transform: 'translateX(0)' })),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class ResetPasswordComponent implements OnInit {
  otpForm: FormGroup;
  resetPasswordForm: FormGroup;
  currentStep = 1;
  otpError = false;
  errorMessage = '';

  otpConfig = {
    length: 5,
    allowNumbersOnly: true,
    inputStyles: {
      width: '3em',
      height: '3em',
      border: '2px solid rgba(255, 255, 255, 0.2)',
      borderRadius: '12px',
      background: 'rgba(255, 255, 255, 0.08)',
      color: 'white',
      fontSize: '1.75em',
      fontWeight: '500',
      textAlign: 'center',
      transition: 'all 0.2s ease',
      caretColor: '#fff',
    },
  };

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
    });

    this.resetPasswordForm = this.fb.group({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        passwordStrengthValidator,
      ]),
    });
  }

  ngOnInit(): void {}

  get passwordCtrl() {
    return this.resetPasswordForm.get('password');
  }

  get passwordStrength(): string {
    return determinePasswordStrength(this.passwordCtrl?.value || '');
  }

  nextStep(): void {
    if (this.otpForm.valid) {
      this.currentStep = 2;
    } else {
      this.otpError = true;
      this.errorMessage = 'Please enter a valid OTP.';
    }
  }

  resetPassword(): void {
    if (this.resetPasswordForm.valid) {
      const msisdn = this.route.snapshot.queryParamMap.get('msisdn');
      const otpValue = this.otpForm.value.otp;
      const newPassword = this.resetPasswordForm.value.password;
  
      this.authService.resetPassword(msisdn!, otpValue, newPassword).subscribe({
        next: () => {
          // Open the dialog
          this.dialog.open(PasswordSuccessDialogComponent, {
            width: '500px',
            panelClass: 'custom-dialog', // Optional if additional global styling is needed
          });
        },
        error: (error) => {
          this.otpError = true;
          this.errorMessage = error.message || 'An error occurred. Please try again.';
          this.currentStep = 1;
        },
      });
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }
}
