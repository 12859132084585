import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgOtpInputConfig } from 'ng-otp-input';
import { ICorridorInfo, IRemitAdvice, RemitService } from '../../../../data';
import { IRemitConfirm } from 'projects/dashboard-lib/src/lib/data/types/remit-confirm';
import { transactionDetails } from 'projects/dashboard-lib/src/lib/data/types/transaction-details';
import { RemitCompleteComponent } from '../remit-complete/remit-complete.component';
import { Subscription } from 'rxjs';
import { RemitErrorComponent } from '../remit-error/remit-error.component';
import { ComponentType } from '@angular/cdk/portal';

@Component({
  selector: 'remit-confirm',
  templateUrl: './remit-confirm.component.html',
  styleUrls: ['./remit-confirm.component.scss']
})
export class RemitConfirmComponent {
  @Input() referenceNumber!: string;
  @Input() remitAdvice!: IRemitAdvice;
  @Input() corridorInfo!: ICorridorInfo;

  otpForm!: FormGroup;
  otpError = false;
  errorMessage = '';
  showBackButton = false;

  confirmSubscription: Subscription

  otpConfig: NgOtpInputConfig = {
    length: 5,
    allowNumbersOnly: true,
    inputStyles: {
      width: '3em',
      height: '3em',
      padding: '8px',
      borderRadius: '12px',
      border: '2px solid rgb(108, 119, 125)',
      background: 'rgba(255, 255, 255, 0.08)',
      // color: 'rgb(108, 119, 125)',
      fontWeight: '500',
      textAlign: 'center',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    },
  };

  constructor(
    private fb: FormBuilder,
    private remitService: RemitService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
    });
  }

  get otpControl() {
    return this.otpForm.get('otp');
  }

  onOtpChange(otp: string): void {
    this.otpControl?.setValue(otp);
    if (this.otpError) {
      this.otpError = false;
    }
  }

  confirmRemit(): void {
    if (this.otpForm.valid) {
      const remitConfirm: IRemitConfirm = {
        reference: this.referenceNumber,
        confirmationToken: this.otpForm.value.otp,
      };

      this.confirmSubscription = this.remitService.confirmRemit(remitConfirm).subscribe({
        next: (response) => this.handleRemitStatus(response),
        error: (error) => this.handleConfirmError(error),
      });
    }
  }

  handleRemitStatus(response: any): void {

    console.log('remit confirm response', response)

    let dialogComponent: ComponentType<unknown>

    if (response.status === 'SUCCESS' || response.status === 'PROCESSING') {
      dialogComponent = RemitCompleteComponent
    } else {
      dialogComponent = RemitErrorComponent
    }

    console.log('confirm dialog', dialogComponent)

    const dialogRef = this.dialog.open(dialogComponent, {
      data: {
        referenceNumber: this.referenceNumber,
        status: 'success',
        sendAmount: this.remitAdvice.sendAmount,
        beneficiaryAmount: this.remitAdvice.beneficiaryAmount,
        sendCurrency: this.corridorInfo.sendCurrency,
        receiveCurrency: this.corridorInfo.receiveCurrency,
        fxRate: this.corridorInfo.fxRate,
        fee: this.corridorInfo.fee,
        beneficiary: {
          name: `${this.remitAdvice.beneficiary.firstName} ${this.remitAdvice.beneficiary.surname}`,
          msisdn: this.remitAdvice.beneficiary.msisdn,
          country: this.corridorInfo.receiveCountry,
        },
        moneyOperator: this.remitAdvice.moneyOperator,
        timestamp: new Date().toISOString(),
      } as transactionDetails,
      width: '520px',
      maxWidth: '90vw',
      panelClass: 'remit-complete-dialog-container',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  handleConfirmError(error: any): void {

    this.otpError = true;

    switch (error.errorCode) {

      case 'ER-305':

        this.errorMessage = error.errorMessage;
        this.showBackButton = true; 
        break;

      case 'ER-306': // Expired confirmation code
      case 'ER-308': // Too many confirmation attempts
        this.errorMessage = error.errorMessage;
        this.showBackButton = true; // Show back button
        break;

      case 'ER-307': // Invalid Confirmation Token
        this.errorMessage = error.errorMessage || 'Invalid OTP. Please try again.';
        this.showBackButton = false; // Keep confirm button
        break;

      default:
        this.errorMessage = 'An error occurred. Please try again later.';
        this.showBackButton = false; // Keep confirm button
    }

    this.otpForm.reset();
  }

  restartTransaction(): void {
    this.router.navigate(['/']);
  }

  navigateHome(): void {
    this.router.navigate(['/']);
  }
}
