<div class="overlay">
    <div class="popup">
        <h2>Where are you sending from?</h2>
        <div class="country-options">
            <div class="country-option" (click)="selectCountry('AU')">
                <span class="flag-icon flag-icons AU"></span>
                <span>Australia</span>
            </div>
            <div class="country-option" (click)="selectCountry('NZ')">
                <span class="flag-icon flag-icons NZ"></span>
                <span>New Zealand</span>
            </div>
            <!-- For later in development -->
            <!-- <div class="country-option" (click)="selectCountry('GB')">
                <span class="flag-icon flag-icons GB"></span>
                <span>United Kingdom</span>
            </div> -->
        </div>
    </div>
</div>